import { useState, useCallback } from 'react';

export function useToggle(initialState = false) {
  const [open, setOpen] = useState(initialState);

  const toggle = useCallback(() => {
    setOpen(prevState => !prevState);
  }, []);

  return [open, toggle] as const;
}