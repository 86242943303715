import React, { useCallback } from 'react'
import OffCanvus from '../OffCanvus'
import Document from 'src/util/types/Document'
import { NavDocProps } from 'src/util/types/types'
import Timeline from './Timeline'
import Point, { PointShape } from 'src/util/types/Timeline'
import Logger from 'src/API/Logger'

interface TimelineContextProps {
  timeMakers: Document[]
  open: boolean
  toggleOpen: () => void
}

export const TimelineContext = React.createContext<TimelineContextProps | null>(null)

interface TimelineProps {
  showDoc: NavDocProps['onClick']
  closeDocViewer: () => void
}


const TimelineViewer = ({ showDoc, closeDocViewer }: TimelineProps): JSX.Element => {
  const context = React.useContext(TimelineContext)
  //if context is not set, throw error
  if (!context)
    throw new Error('TimelineContext is required to use Timeline component')

  const { timeMakers, open, toggleOpen } = context

  const close = useCallback(() => {
    toggleOpen()
  }, [toggleOpen])

  const openDoc = (doc: Document): void => {
    showDoc?.(doc)
  }

  //calculate the spacing between each point accounting for the width of the point
  //const timeLine = CalculateSpacing(timeMakers)

  timeMakers.sort((a, b) => a.date.year - b.date.year)
  const timePoints = timeMakers.map((doc) => {
    Logger.log('Timeline', 'timePoints', doc)
    let docShape = PointShape.Circle
    if (doc.id.match("DdBk"))
      docShape = PointShape.Circle
    else if (doc.id.match("pb"))
      docShape = PointShape.Square

    return {
      id: doc.id,
      date: doc.date.year,
      title: doc.date.year.toString(),
      pointShape: docShape,
      metadata: doc,
    } as Point;
  })

  return (
    <OffCanvus title='Time Line' position='bottom' minimizable={true} open={open} onMinimize={close}>
      <div className="timeline">
        <Timeline timePoints={timePoints} onClick={openDoc}>
          <Timeline.Tooltip InternalComp={Tooltip}/>
        </Timeline> 
        <div className="line"></div>
      </div>
    </OffCanvus>
  )
}

const Tooltip = ({ data }: { data: any }): JSX.Element| null => {
  const doc = data as Document
  if (!doc || !doc.toolTip) return null
  return (
      <p>{doc.toolTip}</p>
  )
}

export default TimelineViewer
