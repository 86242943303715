import CompileJSON from "../compiler";

export interface IDocument {
  id: string;
  doc_url: string;
  date: {
    year: number;
    month?: number;
    day?: number;
  };
  metadata: any;
  docViewTemplate?: IDocViewTemplate;
}

export interface IDocViewTemplate {
  title: string;
  subtitle: string;
  content: string;
  toolTip: string;
}

export interface IDocCollection {
  docViewTemplate: IDocViewTemplate;
  documents: IDocument[];
}

class Document implements IDocument {
  id: string;
  metadata: any;
  doc_url: string;
  date: {
    year: number;
    month?: number;
    day?: number;
  };

  public title: string;
  public subtitle: string;
  public content: any;
  public toolTip: string;

  constructor(doc: IDocument, template: IDocViewTemplate) {
    this.id = doc.id;
    this.doc_url = doc.doc_url;
    this.metadata = doc.metadata;
    this.date = doc.date;

    this.title = CompileJSON(doc, template.title);
    this.subtitle = CompileJSON(doc, template.subtitle);
    this.content = CompileJSON(doc, template.content);
    this.toolTip = CompileJSON(doc, template.toolTip);
  }
}

export default Document;