import React, { createContext, useCallback } from 'react'
import NavBar from 'src/components/navigation/NavBar'
import NavJSON from 'src/components/navigation/NavJSON'
import DocViewer from '../components/DocViewer'
import Timeline, { TimelineContext } from '../components/Timeline/TimelineViewer'
import TheMap from '../components/Leaflet/Map'
import { useToggle } from 'src/util/toggle'
import type Document from '../util/types/Document'
import Logger from 'src/API/Logger'

interface docContextProps {
  doc: Document | null
  AddToTimeline: (doc: Document) => void
  RemoveFromTimeline: (doc: Document) => void
}

export const docContext = createContext<docContextProps | null>(null)

const keyToString = (key: string): string => {
  return key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
};

const TheTown = (): JSX.Element => {
  //DocViewer
  const [docData, setDocData] = React.useState<Document>()
  const [isOpen, toggleOpen] = useToggle();

  const showDoc = useCallback((doc: Document, e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e?.preventDefault()
    if (!isOpen) toggleOpen()
    setDocData(doc)
  }, [isOpen, toggleOpen])

  const toggleShowDoc = useCallback((doc: Document, e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e?.preventDefault()
    if (docData?.id === doc.id) {
      toggleOpen()
    } else {
      showDoc(doc)
    }
  }, [docData, showDoc, toggleOpen])

  //Timeline
  const [timeMakers, setTimeMakers] = React.useState<Document[]>([])
  const [isOpenTL, toggleOpenTL] = useToggle(true);

  const AddToTimeline = useCallback((doc: Document) => {
    //check if the doc is already in the timeline
    if (timeMakers.find((d) => d.id === doc.id)) return
    Logger.log('Timeline', 'AddToTimeline', doc.id)
    setTimeMakers((prev) => [...prev, doc])
  }, [timeMakers])

  const RemoveFromTimeline = useCallback((doc: Document) => {
    Logger.log('Timeline', 'RemoveFromTimeline', doc.id)
    setTimeMakers((prev) => prev.filter((d) => d.id !== doc.id))
  }, [])

  const closeDoc = useCallback(() => {
    toggleOpenTL()
  }, [toggleOpenTL])

  return (
    <>
      <NavBar>
        <NavJSON path="/theTownNavItems" docOnClick={showDoc} />
      </NavBar>
      <docContext.Provider value={{
        doc: docData ?? null,
        AddToTimeline: AddToTimeline,
        RemoveFromTimeline: RemoveFromTimeline
      }}>
        <DocViewer title={docData ? docData.title : ""} subtitle={docData?.subtitle} open={isOpen} toggler={toggleOpen}>
          <DocViewer.Content content={docData?.content} />
        </DocViewer>
      </docContext.Provider>
      
      <TimelineContext.Provider value={{timeMakers,open: isOpenTL,toggleOpen: toggleOpenTL}}>
        <Timeline showDoc={toggleShowDoc} closeDocViewer={closeDoc} />
      </TimelineContext.Provider>

      <div className='z-50 relative'>
        <TheMap />
      </div>
    </>
  )
}

export default TheTown