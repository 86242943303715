import Logger from "src/API/Logger";

/**
 * Gets the value of a property from an object.
 * @param obj The object to retrieve the property from.
 * @param key The key of the property.
 * @returns The value of the property.
 */
const _getProperty = <T, K extends keyof T>(obj: T, key: K): T[K] => {
  return obj[key];
}

/**
 * Extracts blocks from a raw string.
 * @param raw The raw string to extract blocks from.
 * @returns An array of extracted blocks.
 */
const _extractBlocks = (raw: string): string[] => {
  const regex = /<[^>]+>/g;
  return raw ? raw.match(regex) as string[] : [];
}

/**
 * Compiles a string by replacing blocks with corresponding values from a JSON object.
 * @param json The JSON object to pull data from.
 * @param raw The string to compile.
 * @returns The compiled string.
 */
const _compileJSON = (json: any, raw: string): string => {
  //get all blocks
  const blocks = _extractBlocks(raw);
  if (blocks === null || blocks.length === 0) return raw;

  let compiledText = raw;
  if (blocks.length > 0) {
    blocks.forEach((block) => {

      //Path of where to find the data
      const blockId = block.replace(/<|>/g, '').split('.');
      let obj = json;
      blockId.forEach((id) => {
        if (obj === undefined) {
          const error = `Could not find property ${id} in ${typeof json}`;
          Logger.error(error);
          return error;
        }
        obj = _getProperty(obj, id);
      });

      //Replace the block with the data
      compiledText = compiledText.replace(block, obj);
    });
  }

  return compiledText;
}

/**
 * Compiles a string by replacing blocks with corresponding values from a JSON object.
 * @param data The JSON object to pull data from.
 * @param raw The string to compile.
 * @returns The compiled string.
 */
export const CompileJSON = (data: any, raw: any) => {
  if (typeof raw === 'string') {
    return _compileJSON(data, raw);
  }
  else if (typeof raw === 'object') {
    if (Array.isArray(raw)) {
      return raw.map((r) => _compileJSON(data, r));
    }
    else {
      const keys = Object.keys(raw);
      let compiledObject: any = {};
      keys.forEach((key) => {
        compiledObject[key] = _compileJSON(data, raw[key]);
      });
      return compiledObject;
    }
  }
}

export default CompileJSON;