import { MapContainer, TileLayer } from 'react-leaflet'
import LeafletSearch from './LeafletSearch'
import '../../leaflet/leaflet-monuments/monuments'
import LeafletRuler from './LeafletRuler'
import 'leaflet/dist/leaflet.css'
import React from 'react'
import L from 'leaflet'

declare module 'leaflet' {
  namespace control {
    function extend(props: any): {new(...args: any[]): any} & typeof Control;
  }
}

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


export default function Map (): JSX.Element {
  // CRS transformation parameters are determined thusly:
  // a and c: 0 level images is 256px tall. The same distance on the map is 360'. Converting
  // feet to pixels is 360/256 = 1.40625. pixels to feet is 1/1.40625.
  // b and d: 0,0 in Wilmeth is x = 10', y = 345' (down from nw corner). convert these to pixel units
  // NOTE: 'pixel units' means pixel units at 0 layer.
  const brsSimple = L.extend({}, L.CRS.Simple, {
    transformation: new L.Transformation(1 / 1.40625, 7.111111111, -1 / 1.40625, 245.33333333333)
  })

  return (
    <MapContainer
      crs={brsSimple}
      center={[165, 260]}
      zoom={2}
      minZoom={1}
      maxZoom={5}
      maxBounds={[[-500, -500], [800, 1030]]}
      zoomControl={false}
      maxBoundsViscosity={1.0}
      style={{ height: 'calc(100vh - 48px)' }}>
      <TileLayer
        attribution='&copy; Content on this site was created by Joshua Carpenter and is licensed under a <a href="http://creativecommons.org/licenses/by-sa/4.0/">Creative Commons Attribution-ShareAlike 4.0 International License.</a>'
        url="/tiles/base/{z}_{x}_{y}.png"
      />
      <LeafletRuler />
      <LeafletSearch />
    </MapContainer>
  )
}
