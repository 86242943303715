import React, { useState, type MouseEventHandler } from 'react'

// const BORDER_WIDTH = 10

interface OffCanvusProps {
  title: string
  className?: string
  children?: any
  open?: boolean | false
  position?: string
  minimizable?: boolean
  resizeable?: boolean | false
  onMinimize?: OffCanvusControlsProps['onClick']
  onClose?: OffCanvusControlsProps['onClick']
  UpdateHTML?: (htmlContainer: HTMLElement) => void
}

interface OffCanvusControlsProps {
  onClick?: MouseEventHandler<HTMLDivElement>
  className?: string
  children?: any
  open?: boolean
}

const ToggleButton = ({ onClick, className, open }: OffCanvusControlsProps): JSX.Element => {
  return (
    <div className={`nav-toggle text-lg flex items-center text-center w-full whitespace-normal text-white font-bold py-1 px-1 rounded cursor-pointer justify-between ${className} ${open ? 'x' : ''}`} style={{ left: '10px' }} onClick={onClick}><span className="bar rounded-sm"></span><span className="bar rounded-sm"></span></div>
  )
}
const ButtonControl = ({ onClick, children, className }: OffCanvusControlsProps): JSX.Element => {
  return (
    <div className={`text-lg flex items-center justify-center text-center w-full whitespace-normal text-white font-bold py-1 px-2 rounded cursor-pointer ${className}`} onClick={onClick}>{children}</div>
  )
}

const OffCanvus = (props: OffCanvusProps): JSX.Element => {
  const [width, setWidth] = useState(-100);

  const resizeHanlder = (event: React.MouseEvent<HTMLDivElement>) => {
    const bounds = event.currentTarget.getBoundingClientRect()
    const startPos = event.clientX

    if (startPos - bounds.left > 10) return;
    event.preventDefault()
    const startSize = event.currentTarget.clientWidth

    const mouseMoveHandler = (e: MouseEvent): void => {
      const delta = e.clientX - startPos
      setWidth(startSize - delta)
    }

    const mouseUpHandler = (e: MouseEvent): void => {
      document.removeEventListener('mousemove', mouseMoveHandler)
    }

    document.addEventListener('mousemove', mouseMoveHandler)
    document.addEventListener('mouseup', mouseUpHandler, { once: true })
  }

  const handleClose = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (props.onClose != null) props.onClose(event)
    setWidth(-100)
  }

  // set up the classes
  const classes = ['off-canvus']
  if (props.position != null) {
    classes.push(`off-canvus-${props.position}`)
  }
  if (props.resizeable != null && props.resizeable) {
    classes.push('resizable')
  }
  if (props.open) {
    classes.push('active')
  }



  return (
    <div
      className={`${classes.join(' ')} ${props.className ?? ''}`}
      onMouseDown={resizeHanlder}
      style={{ width: `${width}px` }}
    >
      <div className="off-canvus-heading">

        <div className='off-canvus-controls'>
          {(props.minimizable ?? false) ? 
          <ToggleButton onClick={props.onMinimize} open={props.open} className="hover:bg-white hover:bg-opacity-5"/>
          :
          <ButtonControl onClick={handleClose} className="hover:bg-white hover:bg-opacity-5">&times;</ButtonControl>
          }
          {(props.minimizable ?? false) ? null :
            <ButtonControl onClick={props.onMinimize} className="hover:bg-white hover:bg-opacity-5">&minus;</ButtonControl>
          }
        </div>
        <h3>{props.title}</h3>
      </div>
      <div className="off-canvus-content h-full">
        {props.children}
      </div>
    </div>
  )
}

export default OffCanvus
