import L from 'leaflet'
import 'src/leaflet/leaflet-monuments/monuments.d.ts'
import 'src/leaflet/leaflet-monuments/monuments'
import 'src/leaflet/leaflet-monuments/leaflet-monuments.css'
import { useState } from 'react'
import { useMap } from 'react-leaflet'
import API from 'src/API'

const LeafletSearch = (): any => {
  const [searchTool, setSearchTool] = useState<L.Control.Monuments | undefined>(undefined)
  const [mons, setMons] = useState<L.Monument>()
  const Map = useMap()
  if (mons == null) {
    API.getMonuments().then((mons: any) => {
      setMons(mons.map((mon: any) => {
        return {
          lat: mon.lat,
          lng: mon.lng,
          type: mon.type,
          icon_url: mon.icon_url,
          icon_size: mon.icon_size,
          icon_anchor: mon.icon_anchor,
          img_path: mon.img_path,
          desc: mon.desc
        }
      }))
    })
  }
  
  else{
    const searchToolOptions = {
      position: 'topleft',
      monuments: mons
    }
    if (Map == null) return
    if (searchTool !== undefined) return
    const st = L.monuments(searchToolOptions)
    st.addTo(Map)
    setSearchTool(L.monuments(searchToolOptions))
  }

  return null
}

export default LeafletSearch
