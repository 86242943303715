import React from 'react'
import {docContext} from 'src/pages/theTown'
import OffCanvus from './OffCanvus'
import Logger from 'src/API/Logger'

interface DocViewerProps {
  title: string
  subtitle?: string
  children: any
  open: boolean
  toggler: () => void
}

interface ContentProps {
  content?: any
}

const DocViewer: React.FC<DocViewerProps> & {Content: React.FC<ContentProps>} = ( { title, subtitle, children, open, toggler }) => {
  const context = React.useContext(docContext)
  const {doc, AddToTimeline} = context ?? {doc: null, AddToTimeline: () => {}}
  if (!doc) return <></>

  const onClose = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()
    if(open)
      toggler()

    Logger.log('DocViewer', 'onClose', doc.id)
    context?.RemoveFromTimeline(doc)
  }

  const onMinimize = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()
    if(open)
      toggler()
    
    AddToTimeline(doc)
  }

  return (
    <OffCanvus className='z-1000!' title={doc.title} position='right' resizeable={true} open={open} onClose={onClose} onMinimize={onMinimize}>
      {children}
      <div className="pdf-frame " id="pdf-container">
        { doc.doc_url &&
        (<iframe id="sidebar-pdf" className='w-full h-full' title={title} src={"/pdf/"+doc.doc_url}/>)}
      </div>
      <p className='text-center'>
        <a id="sidebar-oNtab" className='btn' href={'/pdf/'+doc.doc_url} target="_blank" rel="noreferrer">View PDF in New Tab</a>
      </p>
    </OffCanvus>
  )
}

const keyToString = (key: string): string => {
  return key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
};

DocViewer.Content = ({content}: ContentProps): JSX.Element => {
  switch (typeof content) {
    case undefined:
      return <></>
    case 'string':
      return (
        <div>
          <ul>
            {(content as string).split('\n').map((key, index) => (
              <li key={index}>
                {key}
              </li>
            ))}
          </ul>
        </div>
      );
    case 'object':
      return (
        <div>
          <ul>
            {Object.keys(content).map((key, index) => (
              <li key={index}>
                <strong>{keyToString(key)}</strong>: {content[key]}
              </li>
            ))}
          </ul>
        </div>
      );
    default:
      return <></>
  }  
  
}
 DocViewer.Content.displayName = 'DocViewerContent'

export default DocViewer
