import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import TheTown from './pages/theTown';
import './App.css';
import TheProject from './pages/theProject';
import InTheClassRoom from './pages/inTheClassroom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="the-town" element={<TheTown />} />
        <Route path="/" element={<Home />} />
        <Route path="theProject" element={<TheProject />} />
        <Route path="inTheClassroom" element={<InTheClassRoom />} />

        <Route path="*" element={<h1>404</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
