import React, { useEffect } from 'react'
import API from 'src/API'
import { NavDropDown, NavItem } from './NavBar'
import { type NavDocProps } from 'src/util/types/types'
import Document from 'src/util/types/Document'


const NavDocDropDown = (props: NavDocProps): JSX.Element => {
  const [docs, setDocs] = React.useState<Document[]>([])
  useEffect(() => {
    API.getDocData(props.fileName, setDocs)
  }, [props.fileName])

  if (docs.length === 0) return (
    <NavDropDown title={props.title}>
      <NavItem href="#" newTab={false}>Loading...</NavItem>
    </NavDropDown>
  )

  try {
    return (
      <NavDropDown title={props.title}>
        {docs.map((doc: Document) => (
          <NavItem
            key={doc.id}
            href={`pdf/${doc.doc_url}`}
            onClick={(e) => props.onClick && props.onClick(doc, e)}
            newTab={props.newTab}>
            {doc.subtitle ?? doc.id}
          </NavItem>
        ))}
      </NavDropDown>
    )
  } catch (e) {
    console.error(e)
    return <></>
  }
}

export default NavDocDropDown
