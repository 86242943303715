const environment = process.env.NODE_ENV;


const Logger = {
  log(message: any, ...optionalParams: any[]) {
    if (environment === 'development')
    console.log(message, ...optionalParams);
  },
  error(message: any, ...optionalParams: any[]) {
    if (environment === 'development')
      console.error(message, ...optionalParams);
  }
}

export default Logger;