import React, { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import Point from 'src/util/types/Timeline';
import { motion } from 'framer-motion';
import { remToPrercent } from 'src/util/helpers';
import Logger from 'src/API/Logger';

const MIN_PIXELS_BETWEEN_POINTS = 41;

interface TimelineProps {
  timePoints: Point[]
  children?: ReactElement<ToolTipProps>
  onClick?: (data: any) => void
}

import PropTypes from 'prop-types';

const Timeline: React.FC<TimelineProps> & { Tooltip: React.FC<ToolTipProps> } = ({ timePoints, onClick, children }) => {
  const onClickPoint = useCallback((e: React.MouseEvent<HTMLDivElement>, data: any) => {
    e.preventDefault();
    Logger.log('Timeline', 'onClickPoint', data);
    onClick?.(data);
  }, [onClick]);

  if (timePoints.length === 0) {
    return <></>;
  }
  const sortedTimePoints = [...timePoints].sort((a, b) => a.date - b.date);
  // Find the minimum and maximum x-coordinates
  const minX = sortedTimePoints[0].date;
  const maxX = sortedTimePoints[sortedTimePoints.length - 1].date;

  // Calculate the scale factor
  const scaleFactor = maxX !== minX ? 1 / (maxX - minX) : 1;

  // Adjust points to stay within the scaled timeline range
  const finalAdjustedTimePoints = sortedTimePoints.map((point) => {
    point.date = (point.date - minX) * scaleFactor;
    return point;
  });

  const minDistance = MIN_PIXELS_BETWEEN_POINTS / document.body.clientWidth;
  Logger.log('Timeline', 'minDistance', minDistance);

  const pointWidth = 1; // Width of the point element
  const pointWidthPercent = remToPrercent(pointWidth); // Convert to percentage

  let isIncreasing = true;
  for (let i = 1; i < finalAdjustedTimePoints.length; i++) {
    const currentPoint = finalAdjustedTimePoints[i];
    const prevPoint = finalAdjustedTimePoints[i - 1];

    if (currentPoint.date - prevPoint.date < minDistance) {
      // Adjust the position to maintain the minimum distance
      if (currentPoint.height)
        continue;

      currentPoint.date = prevPoint.date + minDistance;

      // Adjust the height of the point
      if (isIncreasing) {
        currentPoint.height = prevPoint.height ? prevPoint.height + 1 : 4;
        currentPoint.height > 4 && (isIncreasing = false);
      }
      else {
        currentPoint.height = prevPoint.height ? prevPoint.height - 1 : 4;
        currentPoint.height < 4 && (isIncreasing = true);
      }
    }

  }
  return (
    <div className="point-container">
      {finalAdjustedTimePoints.map((point, index) => (
        <motion.div
          key={index}
          initial={{ x: `${point.date * 100}%`, opacity: 0 }}
          animate={{ x: `${point.date * 100}%`, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className={`tl-point group ${point.pointShape} hover:scale-110 w-3 h-3 rounded-full hover:z-10`}
          onClick={(e) => onClickPoint(e, point.metadata)}
          style={{
            left: `${(point.date * 100) - (pointWidthPercent + 0.05) * index}%`, // Scale back to percentage
            marginRight: `${pointWidth}rem`, // Scale back to percentage
          }}>

          <span style={{ height: `${point.height ?? 3}rem` }}>{point.title}
            {(children != null) && React.cloneElement(children, { data: point.metadata })}
          </span>
        </motion.div>
      ))}
    </div>
  );
};

interface ToolTipProps {
  data?: any
  InternalComp: React.FC<any>
}

Timeline.propTypes = {
  timePoints: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.element
};

Timeline.Tooltip = ({ data, InternalComp }: ToolTipProps): JSX.Element => {
  const refComp = React.useRef<HTMLDivElement>(null);
  const [displayRight, setDisplayRight] = React.useState(false);

  const handleMouseEnter = useCallback(() => {
    if (refComp.current && refComp.current.parentElement) {
      const {x:parentX} = refComp.current.parentElement.getBoundingClientRect();
      const { width } = refComp.current.getBoundingClientRect();
      const isOverflowingRight = (parentX+width) > window.innerWidth;
      setDisplayRight(isOverflowingRight);
    }
  }, []);
  React.useEffect(() => {
    if (refComp.current && refComp.current.parentElement) {
      refComp.current.style.display = 'block';
      handleMouseEnter();
      refComp.current.style.display = '';
      refComp.current.parentElement.addEventListener('mouseenter', handleMouseEnter);
    }
  }, [displayRight]);

  let comp = InternalComp({ data });
  if (comp === null) {
    return <></>;
  }

  return (
      <div ref={refComp} className={`tooltip ${displayRight ? "right" : "left"} absolute hidden z-10 opacity-0 w-auto mt-1 py-2 text-sm
       bg-dark-second text-white rounded-lg shadow-lg group-hover:opacity-100 group-hover:block transition-opacity duration-300`}>
        {comp}
      </div>
  );
};


Timeline.Tooltip.displayName = 'Tooltip'; // Add display name


export default Timeline;
