import React from 'react'
import API from 'src/API'
import { NavJSONProps } from 'src/util/types/types'
import { NavDropDown, NavItem } from 'src/components/navigation/NavBar'
import NavDocDropDown from 'src/components/navigation/NavDocDD'
import Logger from 'src/API/Logger'

const env = process.env.NODE_ENV

function RenderDropdown(item: any) {
  return (
    <NavDropDown key={item.title} title={item.title}>
      {item.children.map((subItem: any) => {
        return RenderLink(subItem)
      })}
    </NavDropDown>
  )
}

function RenderDocDropDown(item: any, docOnClick: NavJSONProps['docOnClick']) {
  if (item.target === 'docViewer') {
    return (
      <NavDocDropDown key={item.title} title={item.title} fileName={item.url} onClick={docOnClick} />
    )
  }
  const newTab = item.target === '_blank'
  return (
    <NavDocDropDown key={item.title} title={item.title} fileName={item.url} newTab={newTab} />
  )
}

function RenderLink(item: any) {
  switch (item.type) {
    case 'link':
      return (
        <NavItem key={item.title} href={item.url} newTab={item.target === "_blank"}>{item.title}</NavItem>
      )
    default:
      return <p key={item.title}>{item.title}</p>
  }
}



export default function NavJSON({ path, docOnClick }: NavJSONProps) {
  const [data, setData] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    API.getJSON(path)
      .then((res) => {
        setData(res)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [path])

  if (loading) return <></>

  return (
    <>
      {data.map((item: any) => {
        if (item.devOnly) {
          if (env === 'development') {
            if (env !== 'development') return null
            if (item.devOnly === true && !item.title.startsWith('</>')){
              item.title = '</> ' + item.title
            }
          }
          else {
            return null
          }
        }
        switch (item.type) {
          case 'link':
            return RenderLink(item)
          case 'dropdown':
            return RenderDropdown(item)

          case 'docDropDown':
            return RenderDocDropDown(item, docOnClick)

          default:
            return <p>Invalid Config item {item.title}</p>
        }
      })}
    </>
  )
}
