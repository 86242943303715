import NavBar from '../components/navigation/NavBar'
import NavItem from '../components/navigation/NavItem'
import React from 'react';
import TheProject from './theProject';
import InTheClassroom from './inTheClassroom';


const HomePage = (): JSX.Element => {
  const searchParams = new URLSearchParams(window.location.search);
  const doc = searchParams.get('view');

  return (
    <div>
      <NavBar>
        <NavItem href="/">HOME</NavItem>
        <NavItem href="/the-town">THE TOWN</NavItem>
      </NavBar>
      <main className="flex min-h-screen flex-col items-center justify-between">

        <div className='flex flex-col items-center justify-center'>
          <img src="banner.png" alt="alternative" width="90%" className="w-full"></img>
        </div>

        <div className='p-16 home-content' style={{ maxWidth: '800px' }}>
          <Content view={doc} />
        </div>
      </main >
    </div>
  );
};

const Content = ({ view }: { view: string | null }): JSX.Element => {
  switch (view) {
    case 'theProject':
      return <TheProject />
    case 'intheclassroom':
      return <InTheClassroom />
    default:
      return <HomeSummary />
  }
}

const HomeSummary = (): JSX.Element => {
  return (<>
    <div className='pb-24' id="band">
      <h2 className='p-0'><u>BOUNDARY TRACER</u></h2><br></br>

      {/*<h2 className="text-3xl tracking-wider p-4 text">THE PROJECT</h2>*/}
      <p className="w3-justify">Many surveyors find boundary retracement a uniquely rewarding 
      part of their profession. But non-surveyors need substantial training before they can 
      experience and appreciate the complexities of the work. Boundary Tracer is an educational 
      tool that allows you to experience the entire process of boundary retracement – from 
      records research to final determination – within a simplified environment that retains 
      the evidential, historical, and geometric dimensions of the boundary retracement problem. 
      {/* <a className='underline' href="?view=theProject">Read more...</a> */}
      </p>
    </div>
    <div className="pb-24" id="band">
      <h2 className="w3-wide">CONTENT</h2>
      <p className="w3-justify">The Town of Wilmeth offers many boundary conflicts for you to 
      assess. Eleven prompts present these conflicts in increasing order of complexity. You can 
      look for written and physical evidence to provide a solution to each problem.<br></br><br></br>
      There are 140 years of land division, consolidation, and conveyance recorded in 21 surveys 
      and 50 deeds. These recorded documents are simplified to remove details that distract from the 
      core experience.<br></br><br></br>
      The interactive map of the town lets you use measurements from surveys and legal descriptions 
      to search for monumentation. After analyzing written and physical evidence, the retracement lines 
      can be drawn on the town and compared to the evidence of occupation.
      {/* <a className='underline' href="?view=intheclassroom">Read more...</a> */}
      </p>
    </div>
    <div className="w3-container w3-content w3-center w3-padding-64" id="band">
      <h2 className="w3-wide">EXPLORE</h2>
      <p className="w3-justify">Boundary Tracer is not designed as a self-study tool. It is intended 
      to be used in a group setting under the instruction of a knowledgeable professional who can guide 
      learners through the retracement process and encourage productive discussion and debate. But, 
      please do some solo exploring. “The Town” tab at the top of this page will take you to the main 
      interface. Once there, look for the “Tutorial” document. The tutorial will welcome you to the Town 
      of Wilmeth and walk you through a few basic tasks to get you ready to tackle the prompts.<br></br><br></br>
      This project is still a work in progress. Please consider sending us your thoughts and feedback.
      </p>
    </div>
    <div className='pt-24' id="contact">
      <h2>CONTACT</h2>
      <p className='text-center text-slate-500'><i>Have comments or corrections? Please drop me a note.</i></p>
      <div className="py-8">
        <div className="w3-col m6 w3-large w3-margin-bottom">
          <i className="fa fa-user" style={{ maxWidth: '30px' }}></i> Joshua Carpenter<br></br>
          <i className="fa fa-map-marker" style={{ maxWidth: '30px' }}></i> Member of <a className='underline' href='https://gdslab.org/' target='_blank' rel='noreferrer'>GDSL</a> - Purdue University<br></br>
          <i className="fa fa-envelope" style={{ maxWidth: '30px' }}> </i> Email: nurveyor@gmail.com<br></br>
        </div>
      </div>
    </div>
  </>)
}

export default HomePage;