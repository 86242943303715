import L from 'leaflet'
(function () {

  L.Control.Monuments = L.Control.extend({
    link_tool_for_toggle: function (toggle_tool) {
      this._toggle_tool = toggle_tool
    },
    options: {
      position: 'topright',
      monuments: []
    },
    onAdd: function (map) {
      this._map = map
      map.MonumentControl = this
      this._container = L.DomUtil.create('div', 'leaflet-bar')
      this._container.classList.add('leaflet-monuments')
      L.DomEvent.disableClickPropagation(this._container)
      L.DomEvent.on(this._container, 'click', this._toggle, this)

      this._choice = false

      // Load Monuments
      this._mons_pos_lookup = {}
      if (this.options.monuments == null) {
        this.options.monuments = []
      }
      this.options.monuments.forEach(mon => {
        const key = `${mon.lat},${mon.lng}`
        this._mons_pos_lookup[key] = mon
      })

      if (L.Control.ToolToggle == null) {
        L.Control.ToolToggle = [this]
      } else {
        L.Control.ToolToggle.push(this)
      }

      return this._container
    },
    onRemove: function () {
      L.DomEvent.off(this._container, 'click', this._toggle, this)
    },

    deselect: function () {
      this._toggle();
    },

    _toggle: function () {
      this._choice = !this._choice
      if (this._choice) {
        L.Control.ToolToggle.forEach(tool => {
          if (tool !== this && tool._choice) {
            tool.deselect()
          }
        })
        this._map.doubleClickZoom.disable()
        // L.DomEvent.on(this._map._container, 'keydown', this._escape, this);
        this._container.classList.add('leaflet-monuments-clicked')
        this._map._container.classList.add('leaflet-monuments-cursor')
        this._map.on('click', this._clicked, this)
        this._map.on('contextmenu', this._toggle, this)
      } else {
        this._map.doubleClickZoom.enable()
        // L.DomEvent.off(this._map._container, 'keydown', this._escape, this);
        this._container.classList.remove('leaflet-monuments-clicked')
        this._map._container.classList.remove('leaflet-monuments-cursor')
        this._map.off('click', this._clicked, this)
        this._map.off('contextmenu', this._toggle, this)
      }
    },
    _clicked: function (e) {
      const lat = Math.round(e.latlng.lat / 5) * 5
      const lon = Math.round(e.latlng.lng / 5) * 5
      let mon
      /*
      for (let x = lat - 5; x <= lat + 5; x += 5) {
        for (let y = lon - 5; y <= lon + 5; y += 5) {
          const key = `${x},${y}`
          mon = this._mons_pos_lookup[key]
          if (mon && !mon.Found) {
            this._uncover_mon(mon)
          }
        }
      }
      */
      mon = this._mons_pos_lookup[`${lat},${lon}`]
      if (mon && !mon.Found) {
        this._uncover_mon(mon)
      }
    },
    _uncover_mon: function (mon) {
      mon.Found = true
      let mon_icon
      if (mon.icon_url) {
        mon_icon = L.icon({
          iconUrl: mon.icon_url,
          shadowUrl: mon.icon_shadow_url,

          iconSize: mon.icon_size,
          iconAnchor: mon.icon_anchor,

          shadowSize: mon.icon_size,
          shadowAnchor: mon.icon_anchor
        })
      } else {
        mon_icon = L.Icon.Default.prototype
      }

      const popup = L.popup({ autoClose: false, closeOnClick: true, closeButton: true, className: 'mon-popup' })
      const marker = L.marker([mon.lat, mon.lng], { icon: mon_icon, title: mon.type }).addTo(this._map)

      popup.setLatLng([mon.lat, mon.lng])
      popup.setContent(`<div class="mon-popup"><img src=${mon.img_path}><h3>${mon.type}</h3><p>${mon.desc}</p></div>`)
      popup.openOn(this._map)
      marker.bindPopup(popup)
      marker.off('click')
      marker.on('click', function (e) {
        if (!L.Control.ToolToggle.some(tool => tool._choice)) {
          marker.openPopup()
        }
        else {
          this._map.fire('click', { latlng: L.latLng(e.latlng.lat, e.latlng.lng) });
        }
      })
    }
    // _escape: function () { },
  })
  L.monuments = function (options) {
    return new L.Control.Monuments(options)
  }
})()