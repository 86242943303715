import L from 'leaflet'
import 'src/leaflet/leaflet-ruler/leaflet-ruler.js'
import 'src/leaflet/leaflet-ruler/leaflet-ruler.d.ts'
import 'src/leaflet/leaflet-ruler/leaflet-ruler.css'
import { useEffect } from 'react'
import { useMap } from 'react-leaflet'

const LeafletRuler = (): any => {
  const Map = useMap()
  
  useEffect(() => {
    const rulerToolOptions = {
      position: 'topleft', // Leaflet control position option
      lineStyle: {
        color: 'black',
        weight: 5
      },
      lengthUnit: { // You can use custom length units. Default unit is kilometers.
        display: 'ft', // This is the display value will be shown on the screen. Example: 'meters'
        decimal: 2, // Distance result will be fixed to this value.
        factor: null // This value will be used to convert from kilometers. Example: 1000 (from kilometers to meters)
      },
      angleUnit: {
        display: '&deg;', // This is the display value will be shown on the screen. Example: 'Gradian'
        decimal: 1, // Bearing result will be fixed to this value.
        factor: null // This option is required to customize angle unit. Specify solid angle value for angle unit. Example: 400 (for gradian).
      }
    }
    if (Map == null) return
    //if(L.Control.Ruler !== null) return
    let ruler = L.Ruler(rulerToolOptions)
    ruler.addTo(Map)
  }, [Map])

  return null
}

export default LeafletRuler
