import L from 'leaflet'
(function () {
  L.Control.Ruler = L.Control.extend({
    link_tool_for_toggle: function (toggle_tool) {
      this._toggle_tool = toggle_tool
    },
    options: {
      position: 'topright',
      circleMarker: {
        color: 'black',
        radius: 4
      },
      lineStyle: {
        color: 'black',
        weight: 3
      },
      lengthUnit: {
        display: 'ft',
        decimal: 2,
        factor: null,
        label: 'Distance:'
      },
      angleUnit: {
        display: '&deg;',
        decimal: 2,
        factor: null,
        label: 'Bearing:'
      }
    },

    onAdd: function (map) {
      this._map = map
      this._container = L.DomUtil.create('div', 'leaflet-bar')
      this._container.classList.add('leaflet-ruler')
      L.DomEvent.disableClickPropagation(this._container)
      L.DomEvent.on(this._container, 'click', this._toggle, this)
      this._choice = false
      this._defaultCursor = this._map._container.style.cursor
      this._allLayers = L.layerGroup()
      if (L.Control.ToolToggle == null) {
        L.Control.ToolToggle = [this]
      } else {
        L.Control.ToolToggle.push(this)
      }
      return this._container
    },

    onRemove: function () {
      L.DomEvent.off(this._container, 'click', this._toggle, this)
    },

    deselect: function () {
      if (this._clickCount > 0) {
        this._closePath();
        this._toggle();
      }
      else {
        this._toggle();
      }
    },

    _toggle: function () {
      this._choice = !this._choice
      this._clickedLatLong = null
      this._clickedPoints = []
      if (this._choice) {
        L.Control.ToolToggle.forEach(tool => {
          if (tool !== this && tool._choice) {
            tool.deselect()
          }
        })
        this._map.doubleClickZoom.disable()
        // L.DomEvent.on(this._map._container, 'keydown', this._escape, this);
        this._container.classList.add('leaflet-ruler-clicked')
        this._clickCount = 0
        this._tempLine = L.featureGroup().addTo(this._allLayers)
        this._tempPoint = L.featureGroup().addTo(this._allLayers)
        this._pointLayer = L.featureGroup().addTo(this._allLayers)
        this._polylineLayer = L.featureGroup().addTo(this._allLayers)
        this._allLayers.addTo(this._map)
        this._map._container.style.cursor = 'crosshair'
        this._map.on('click', this._clicked, this)
        this._map.on('mousemove', this._moving, this)
        this._map.on('contextmenu', this._closePath, this) // Add contextmenu (right-click) event listener to finish measurement
        this._map.on('contextmenu', this._toggle, this)
      } else {
        this._map.doubleClickZoom.enable()
        // L.DomEvent.on(this._map._container, 'keydown', this._escape, this);
        this._container.classList.remove('leaflet-ruler-clicked')
        // this._map.removeLayer(this._allLayers);
        this._allLayers = L.layerGroup()
        this._map._container.style.cursor = this._defaultCursor
        this._map.off('click', this._clicked, this)
        this._map.off('mousemove', this._moving, this)
        this._map.off('contextmenu', this._closePath, this) // Remove the contextmenu (right-click) event listener when exiting measurement mode
        this._map.off('contextmenu', this._toggle, this)
      }
    },

    _clicked: function (e) {
      this._clickedLatLong = {
        lat: Math.round(e.latlng.lat),//lat: Math.round(e.latlng.lat / 5) * 5,
        lng: Math.round(e.latlng.lng)//lng: Math.round(e.latlng.lng / 5) * 5
      }
      this._clickedPoints.push(this._clickedLatLong)
      // var dot = L.circleMarker(this._clickedLatLong, this.options.circleMarker).addTo(this._pointLayer); //could change 'var' to 'const'
      // dot.on('contextmenu', function () {
      //   dot.remove();
      // });
      if (this._clickCount > 0 && !e.latlng.equals(this._clickedPoints[this._clickedPoints.length - 2])) {
        let text
        text = this._result.Prefix + '&nbsp;' + this._result.Bearing.toFixed(this.options.angleUnit.decimal) + this.options.angleUnit.display + '&nbsp;' + this._result.Suffix + '&nbsp &nbsp' + this._result.Distance.toFixed(this.options.lengthUnit.decimal) + '&nbsp;' + this.options.lengthUnit.display
        if (this._movingLatLong) {
          const tooltipContent = document.createElement('span');
          tooltipContent.innerHTML = text;

          const polygon = L.polyline([this._clickedPoints[this._clickCount - 1], this._movingLatLong], this.options.lineStyle)
            .addTo(this._polylineLayer)
            .bindTooltip(tooltipContent, { permanent: true, offset: L.point(0, 0), className: 'result-tooltip', direction: 'center' })
            .addTo(this._pointLayer)
            .openTooltip();
          polygon.on('contextmenu', function () {
            polygon.remove()
          })
        }
      }
      this._clickCount++
      if (this._clickCount >= 2) {
        this._closePath()
        this._clickedPoints = []
      }
    },

    _moving: function (e) {
      if (this._clickedLatLong) {
        L.DomEvent.off(this._container, 'click', this._toggle, this)
        this._movingLatLong = {
          lat: Math.round(e.latlng.lat),//lat: Math.round(e.latlng.lat / 5) * 5,
          lng: Math.round(e.latlng.lng)//lng: Math.round(e.latlng.lng / 5) * 5
        }
        if (this._tempLine) {
          this._map.removeLayer(this._tempLine)
          this._map.removeLayer(this._tempPoint)
        }
        let text
        this._addedLength = 0
        this._tempLine = L.featureGroup()
        this._tempPoint = L.featureGroup()
        this._tempLine.addTo(this._map)
        this._tempPoint.addTo(this._map)
        this._calculateBearingAndDistance()
        text = this._result.Prefix + '&nbsp;' + this._result.Bearing.toFixed(this.options.angleUnit.decimal) + this.options.angleUnit.display + '&nbsp;' + this._result.Suffix + '&nbsp &nbsp' + this._result.Distance.toFixed(this.options.lengthUnit.decimal) + '&nbsp;' + this.options.lengthUnit.display
        L.polyline([this._clickedLatLong, this._movingLatLong], this.options.lineStyle).addTo(this._tempLine)
        L.circleMarker(this._movingLatLong, this.options.circleMarker).bindTooltip(text, { sticky: true, offset: L.point(0, -40), className: 'moving-tooltip' }).addTo(this._tempPoint).openTooltip()
      }
    },

    // This is for CRS where coordinates are X and Y, not Lat and Long
    _calculateBearingAndDistance: function () {
      const toDeg = 180 / Math.PI
      const y1 = this._clickedLatLong.lat; const x1 = this._clickedLatLong.lng; const y2 = this._movingLatLong.lat; const x2 = this._movingLatLong.lng
      const dy = y2 - y1
      const dx = x2 - x1
      let prefix = 'N'
      let suffix = 'E'
      let brng = Math.atan2(dx, dy) * toDeg
      // Convert to Bearing
      if (brng > 90) {
        brng = 180 - brng
        prefix = 'S'
      } else if (brng < -90) {
        brng = 180 + brng
        prefix = 'S'
        suffix = 'W'
      } else if (brng < 0) {
        brng = -brng
        suffix = 'W'
      }
      // Round to nearest 1/2 degree
      const deg = Math.floor(brng)
      let dec = brng - deg
      if (dec >= 0.75) {
        dec = 1
      } else if (dec > 0.25) {
        dec = 0.5
      } else {
        dec = 0
      }
      brng = deg + dec

      const distance = Math.sqrt(dx * dx + dy * dy)
      this._result = {
        Bearing: brng,
        Prefix: prefix,
        Suffix: suffix,
        Distance: distance
      }
    },

    _closePath: function () {
      this._map.removeLayer(this._tempLine)
      this._map.removeLayer(this._tempPoint)
      if (this._clickCount <= 1) this._map.removeLayer(this._pointLayer)
      this._choice = false
      L.DomEvent.on(this._container, 'click', this._toggle, this)
      this._toggle()
    },

    _escape: function (e) {
      if (e.keyCode === 27) {
        if (this._clickCount > 0) {
          this._closePath();
        }
      }
    },
  })
  L.Ruler = function (options) {
    return new L.Control.Ruler(options)
  }

})()
