import React from 'react';

const TheProject = (): JSX.Element => {
  return (
    <>
      <h2 className="w3-wide">THE PROJECT</h2>
      <p className="w3-justify">
        Locating boundary lines is a complex task. It is a puzzle requiring historic research, evidence
        collection, precise measurement, and careful deduction. Many professional surveyors find exercising
        this wide range of skills to be a uniquely fulfilling part of their work. However, acquiring the broad
        set of knowledge and expertise needed to complete boundary retracements takes some formal education and
        years of experience, making it difficult for folks who know little about surveying to understand or acquire
        a taste for the work of the boundary surveyor. <br></br><br></br>
        This project aims to build an in-class exercise which simulates the entire process of boundary surveying—from
        records research to final retracement—which gives students a simplified but accurate feel for the task of
        retracement.<br></br><br></br>
        In its current form, the simulation is intended as a pen - and - paper
        exercise.This website is intended only to demonstrate the current draft of the retracement simulation.
        The main two components of the simulation are the Town of Wilmeth and a collection of deeds and surveys
        documenting
        land conveyance and division from 1809 to 1940. Students trace deeds and surveys onto the Town of Wilmeth to
        anticipate
        where monuments might be found.Once search areas are defined, the location of monuments are revealed by the
        instructor.Students then
        make final boundary determination by weighing the written evidence, monumentation, and evidence of
        occupation.<br></br><br></br>
        The target audience is students with little to no background in surveying, such as high school students or
        students
        in an introductory course on boundary surveying.To make the material accessible, many aspects of boundary
        surveying
        have been simplified or entirely removed in this simulation.Here are some notable examples:<br></br>
      </p >
      <br></br>
      <ul className="list">
        <li>Easements are not considered.</li>
        <li>Unwritten rights (adverse possession, prescriptive easements, etc.) are not directly considered.</li>
        <li>The PLSS is not simulated.</li>
        <li>Deeds and plats are presented in a basic form.</li>
        <li>All correct interpretations (and most incorrect interpretations) of boundary evidence place
          the boundary corner on a 5&apos; x 5&apos; grid.</li>
      </ul>
      <br></br>
      <p className="w3-justify">
        The goal of simplification is to remove some real-world considerations, allowing students to focus on
        interpreting
        written evidence, weighing all evidence, and making boundary determinations in an environment where retracement
        decisions
        affect surrounding parcels. Simplification should not mislead. If any of the recording institutions, legal
        description
        types, convenance methods, or principles of retracement presented in this material cause students to intuit
        something false about
        the corresponding real-world institutions, conventions, or theories, please notify me - simplification which
        borders on misrepresentation should be fixed.
      </p>
    </>
  );
};

export default TheProject;