import React from 'react';

const InTheClassroom = (): JSX.Element => {
  return (
    <>
      <h2 className="w3-wide">CLASSROOM TESTING</h2>
      <p className="w3-justify">
        This retracement simulation was first developed as a 2-hour exercise for a class of 15 high school students.<br></br><br></br>
        To give students some intuition about how boundaries are defined prior to starting the simulation, the class was divided
        into teams of 3 and each was verbally assigned a portion of floorspace of the room. The teams were then asked to write a
        description of their territory with enough detail that someone could walk into the room the next day and find the exact
        boundary of their territory. This took about 15 minutes.<br></br><br></br>
        This exercise was followed by a 15-minute lecture about what boundaries are and how they are marked. This presentation
        focused on the fact that boundaries are (mostly) invisible lines, any markers or descriptions are not the boundary itself,
        but just an attempt to make the line reproducible. To demonstrate this point, the descriptions from the first exercise were
        read the instructor attempted to retrace some of the descriptions. Conflicting elements and over lapping descriptions
        become immediately apparent, and students were lead in a debate about how to choose between conflicting evidence.<br></br><br></br>
        With about 80 minutes left in the class, the students were divided into teams of 5 and introduced to the boundary
        retracement simulation. <br></br><br></br>
        Each team was given a copy of the Town of Wilmeth plotted at a scale of 1”=10&apos; and a copy of the deeds and plats stored
        in the county recorder&apos;s office. Plats are records of divisions of land. Deeds are the instrument which transfers land from
        one person to another. Each student was given a custom bearing protractor and an engineer&apos;s scale which could be used to
        trance surveys and descriptions onto the Town. Students were instructed to make careful measurements with protractor and
        scale, but to snap boundary corners to the 5&apos; x 5&apos; grid printed on the Town.<br></br><br></br>
        Prompt 1 was the first task. The prompt was read aloud, and the entire class solved the task together. The prompt asked
        the surveyor to mark the line between Thomas Malone (the client) and his neighbor. Students were told to find the deed to
        the property of Thomas Malone using the current Tax Map as a guide. The Tax Map contains the reference for the current
        deed for the property owned by Thomas Malone. Following the reference, the students looked up the current deed and were
        asked to trace the deed on the ground.<br></br><br></br>
        The legal description in the deed was read aloud and the various phrases were explained. The instructor pointed out
        the description would trace out the boundaries of the property and that the first call was important to find on the
        ground: “Beginning at the northeasterly corner of Lot 3 of the Huler Subdivision…”. The plat of the Huler Subdivision
        had to be looked up and traced out until the northeasterly corner of lot 3 could be roughly determined. To start tracing
        the subdivisions, students needed to identify a point in the plat which could also be identified on the Town of Wilmeth.
        Students quickly recognized that a monument box on the Town of Wilmeth seemed to match the position of a stone set in the
        survey and started their initial retracement from that point.<br></br><br></br>
        Students struggled at first to filter through all the information on the plat. But given a little time to ponder and a
        few hints, the students were able to trace the subdivision out on the ground until they had a reasonable guess of the
        position of the northeasterly corner of lot 3.<br></br><br></br>
        Having estimated the point of beginning, the students returned to the deed&apos;s legal description to finish tracing.
        Some students looked though neighboring deeds and found reference to a recently recorded plat which revealed the position
        of an additional monument in the area. Students then circled areas where they would like to look for monuments.
        The instructor then reported the position of monuments in the circled areas.<br></br><br></br>
        With the monument positions added to the map, students could then weigh all the evidence and finalize their determination
        of the boundary line.<br></br><br></br>
        Prompt 2 followed prompt 1. This problem also asked the students to retrace a parcel in the Huler subdivision.
        The first step is always the same, the students needed to consult the tax map to get a copy of the current deeds.
        One student realized that they could extend their work from Prompt 1 southward to recreate the rest of the Huler
        subdivision. However, using this method put the initial retracement in conflict with an unrecorded survey included with
        the prompt. Students then argued which survey best matched the evidence, their retracement or the unrecorded survey.<br></br><br></br>

      </p>
    </>
  );
};

export default InTheClassroom;