interface Point {
  id: string;
  date: number;
  height?: number;
  title?: string;
  description?: string;
  link?: string;
  spacing?: number; //in px from left
  pointShape: PointShape
  metadata?: any;
}


export interface Page{
  index: number;
  Points:{[id: Point['id']]: Point};
}

export enum PointShape {
  Circle = 'circle',
  Square = 'square',
}

export default Point;
