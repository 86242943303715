import { get } from "http";
import Logger from "./Logger";
import Document, { IDocCollection, IDocument } from "src/util/types/Document";

const API = {

  getJSON: async (url: string) => {
    return await fetch(`/json/${url}.json?timestamp=` + new Date().getTime())
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          return data;
        } else {
          throw new Error('API Error: ' + res.status);
        }
      })
      .catch((error) => {
        // Handle the error here
        Logger.error(error);
      });
  },

  getDocData: async (name: string, setData: (value: any) => void) => {
    return await fetch(`/json/${name}.json?timestamp=` + new Date().getTime())
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          const colection = data['docCollection'] as IDocCollection;
          let documents = colection.documents as IDocument[];
          const template = colection.docViewTemplate;
          let compiledDocs = documents.map((doc) => {
            return new Document(doc, template);
          });
          setData(compiledDocs);
        } else {
          throw new Error('API Error: ' + res.status);
        }
      })
      .catch((error) => {
        // Handle the error here
        Logger.error(error);
      });
  },

  getMapDocData: async (name: string, setData: (value: any) => void) => {
    return await fetch(`/json/${name}.json?timestamp=` + new Date().getTime())
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          setData(data);
        } else {
          throw new Error('API Error: ' + res.status);
        }
      })
      .catch((error) => {
        // Handle the error here
        Logger.error(error);
      });
  },
  getMonuments: async () => {
    return await fetch(`/json/monuments.json?timestamp=` + new Date().getTime())
      .then(async (res) => await res.json())
      .catch((error) => {
        // Handle the error here
        Logger.error('API Error: ' + error);
      });
  }
};

export default API