import React from 'react'

const image = '/logo.jpg'

const NavBar = ({ children }: any): JSX.Element => {
  return (
    <header>
      <div className='nav-content'>
        <img className='rounded-full h-8 my-2' src={image} alt="logo" />
        {children}
      </div>
    </header>
  )
}

export const NavDropDown = ({ title, children }: any): JSX.Element => {
  return (
    <div className='dropdown'>
      <button className='dropbtn'>{title}</button>
      <ul className='dropdown-content'>
        {children}
      </ul>
    </div>
  )
}

interface NavItemProps {
  newTab?: boolean
  children: string
  href: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const NavItem = ({ children, href, onClick, newTab }: NavItemProps): JSX.Element => {
  return (
    <a className='nav-item' href={href} onClick={onClick} target={newTab ? '_blank' : undefined} rel={newTab ? 'noreferrer' : undefined}>{children}</a>
  )
}

export default NavBar
