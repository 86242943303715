import PropTypes from 'prop-types'
import React from 'react'
import { typeValidation } from "../../utils"

interface NavItemProps {
  children: string
  href: string
}

const NavItem = ({ children, href }: NavItemProps): JSX.Element => {
  return (
    <a className='nav-item' href={href}>{children}</a>
  )
}

NavItem.prototype = {
  children: PropTypes.node,
  type: typeValidation('NavItem')
}

NavItem.defaultProps = {
  __TYPE: 'NavItem'
};

export default NavItem
